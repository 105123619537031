<template>
  <div class="supplier-health-stats w-full">
    <div class="supplier-health-stats-main" :style="borderStyle" :class="{'multi-line-stat': multiLine }">
      <div class="text-sm">{{statValue}}</div>
    </div>
  </div>
  
</template>

<script>

export default {
  name: 'travelify-supplier-health-check-stats',
  components: {
  },
  props: {
    statValue: { required: true, type: String },
    multiLine: { required: false, default: false },
    color: { required: false, type: String, default: 'green' },
  },
  computed: {
    // statValue () {
    //   return '0.05s last hour'
    //   // return '0.05s last hour \n12 hours'
    // },
    borderStyle () {
      return {
        borderLeftColor: this.color,
        borderLeftWidth: '6px'
      }
    }
  }
}
</script>

<style scoped>
  .supplier-health-stats .supplier-health-stats-main {
    padding: 10px;
    border-width: 1px 1px 1px 1px;
    border-color: #e2e2e2;
    border-style: solid;
  }
  .supplier-health-stats  .multi-line-stat {
    /* This breaks text into new line */
    white-space: break-spaces; 
    /* https://stackoverflow.com/questions/27774702/is-there-a-way-a-white-space-pre-wrap-texts-next-line-spacing-can-be-larger */
    line-height: 30px;
    top: -15px;
    /* position: relative; */
  }
  
</style>
