<template>
  <div v-if="params.data.isEditable">
    <feather-icon v-if="userHasPermission('apps_integrations_edit')" title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
    <feather-icon v-if="userHasPermission('apps_integrations_edit')" title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="showDeletePopup=true" />
    
    <vs-prompt
      title="Delete Integration"
      accept-text="Delete"
      @accept="deleteIntegrations"
      :active.sync="showDeletePopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to delete the current integration? Upon deletion, corresponding monthly charge will be removed from your subscription</div>
          </div>
        </div>
    </vs-prompt>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/axios'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  name: 'CellRendererIntegrationsActions',
  data () {
    return {
      showDeletePopup: false
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
  },
  methods: {
    onEdit () {
      this.$router.push({ name: 'supplier-integrations-edit', 
        params: { 
          integrationId: this.params.data.threadId,
          supplierId: this.params.data.supplierId,
          applicationId: this.params.data.applicationId
        }
      })
    },
    
    deleteIntegrations () {
      this.params.context.componentParent.deleteIntegration(this.params.data.threadId)
      // this.$vs.loading()
      // axios.delete(`api/supplierintegrations/apps/${this.params.data.applicationId}/integrations/${this.params.data.threadId}`)
      // .then(response => {
      //   this.$_notifySuccess('Integration settings successfully deleted');
      //   this.returnToIntegrationsPage()
      // })
      // .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      // .finally(() => this.$vs.loading.close())
    }
  }
}
</script>
