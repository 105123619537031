<template>
  <travio-center-container v-if="hasLoaded" grid-width="full" :pageTitle="'Suppliers Directory - ' + form.supplierName ">
    <vx-card>
      <div>
        <div class="flex flex-wrap items-center mb-4">
           <vs-avatar 
            :src="form.logoUrl"
            :text="form.supplierName" 
            class="supplier-logo-avatar mr-2" 
            size="60px"
          />
          <div class="ml-2"> 
            <div class="flex flex-wrap items-center"> 
              <h2 class="supplier-name-heading mr-2">{{ form.supplierName }}</h2> <vs-chip color="warning" v-if="form.isPremium">PREMIUM</vs-chip>
            </div>
            <p class="text-muted mt-1">{{ form.productType }}  &#8226;  {{ form.apiType }}</p>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/5 w-full">
            <span class="font-semibold">Description</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <p><span v-html="form.supplierDescription"></span></p>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/5 w-full">
            <span class="font-semibold">Setup Price*</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <p>&pound; {{ form.setupPrice.toFixed(2) }}</p>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col sm:w-1/5 w-full">
            <span class="font-semibold">Monthly Price*</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <p>&pound; {{ form.monthlyPrice.toFixed(2) }}</p>
          </div>
        </div>
        <div class="mb-3">
          <span class="text-muted text-sm">*prices shown above are travelgenix charges &amp; there may be additional supplier charges involved.</span>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/5 w-full">
            <span class="font-semibold">Setup Information</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <span v-if="form.requiresSetup">This supplier requires setup by travelgenix.io</span>
            <span v-if="!form.requiresSetup">Self-service setup</span>
          </div>
        </div>
        <div class="vx-row mb-3" v-if="form.chargesInfo">
          <div class="vx-col sm:w-1/5 w-full">
            <span class="font-semibold">Supplier Charge Information</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <p><span v-html="form.chargesInfo"></span></p>
          </div>
        </div>
        <div class="vx-row mb-3" v-if="form.accountContact">
          <div class="vx-col sm:w-1/5 w-full">
            <span class="font-semibold">Account Setup Information</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <p><span v-html="form.accountContact"></span></p>
          </div>
        </div>
        <div class="vx-row mb-8" v-if="form.postBookContact">
          <div class="vx-col sm:w-1/5 w-full">
            <span class="font-semibold">Post-Booking Support</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <p><span v-html="form.postBookContact"></span></p>
          </div>
        </div>
      </div>

      <div class="mt-8" v-if="checklistItems.length > 0">
        <span class="font-medium text-lg leading-none">Important Checklist Items</span>
      </div>
      <supplier-integrations-checklist v-if="checklistItems.length > 0" :checklistItems="checklistItems"></supplier-integrations-checklist>


      <div class="mt-6" v-if="form.productTypeSearchable">
        <h5 class="font-medium text-lg leading-none mb-4">Supplier Health <span v-if="form.statusUrl" class="text-sm"> &#160;<a :href="form.statusUrl" target="_blank" >Open supplier status page</a></span></h5>
        <div v-if="!healthCheckItems || healthCheckItems.length == 0">Loading supplier health....</div>
        <supplier-health-check-list v-if="healthCheckItems && healthCheckItems.length > 0" :healthCheckItems="healthCheckItems" />
      </div>

      <div class="mt-6">
        <span class="font-medium text-lg leading-none">My {{ form.supplierName }} Accounts</span>
        <span v-if="!form.productTypeSearchable && form.statusUrl" class="text-sm"> &#160;<a :href="form.statusUrl" target="_blank" >Open supplier status page</a></span>
      </div>
      <div class="vx-row">
        <div v-if="userHasPermission('apps_integrations_add')" class="vx-col w-full mt-4">
          <vs-button @click="showAddIntegrationPopup=true;selectedChecklistItems=[]" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
        </div>
      </div>
      <div>
        <supplier-integrations-list :integrations="integrationsData" :applicationId="applicationId" @onDeleteIntegration="handleDeleteIntegration" />
      </div>
    </vx-card>
    <vs-prompt
      title="Add Supplier Integration"
      accept-text="Continue"
      :is-valid="enableConfirmButton"
      @cancel="closeAddIntegrationDialog"
      @close="closeAddIntegrationDialog"
      @accept="addIntegration"
      :active.sync="showAddIntegrationPopup">

      <div class="vx-row mt-2 mb-2">
        <div class="vx-col w-full" v-if="form.requiresSetup">
          <span>{{requiresSetupVerbiage}}</span> 
        </div>
        <div class="vx-col w-full mb-2" v-if="!form.requiresSetup">
          <span>{{doesNotRequireSetupVerbiage}}</span> 
        </div>
        
      </div>
      <div class="vx-row mb-2" v-if="checklistItems.length > 0">
        <div class="vx-col w-full">
          Checklist Items:
        </div>
        <div v-for="(checklist, checklistIndex) in checklistItems" :key="checklistIndex" class="vx-col w-full mt-2">
          <vs-checkbox  :title="checklist.description" v-model="selectedChecklistItems" :vs-value="checklist.id">
            {{checklist.title}}
          </vs-checkbox>  
        </div>
      </div>
          
      </vs-prompt>

  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import SupplierIntegrationsList from './SupplierIntegrationsList.vue'
import SupplierIntegrationsChecklist from './SupplierIntegrationsChecklists.vue'
import SupplierHealthCheckList from './SupplierHealthCheckList.vue'
import TravelifySupplierHealthCheckStats from '@/components/travio-pro/TravelifySupplierHealthCheckStats.vue'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    SupplierIntegrationsList,
    SupplierIntegrationsChecklist,
    SupplierHealthCheckList,
    TravelifySupplierHealthCheckStats
  },
  props: { 
    supplierId: { required: true },
    applicationId: { required: true }
  },
  data () {
    return {
      form: {
        supplierName: '',
        supplierDescription: '',
        productType: '',
        logoUrl: '',
        setupPrice: 0,
        monthlyPrice: 0,
        statusUrl: null,
        
        chargesInfo: '',
        accountContact: '',
        postBookContact: '',
        requiresSetup: true,
        productTypeSearchable: false
      },
      integrationsData: [],
      checklistItems: [],
      healthCheckItems: [],
      showAddIntegrationPopup: false,
      hasLoaded: false,
      selectedChecklistItems: []
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    requiresSetupVerbiage () {
      
      if((this.form.setupPrice + this.form.monthlyPrice) == 0) {
        return 'This integration requires setup by travelgenix.io and has no fees (no setup charge or monthly fees)).'
      }
      return `This supplier requires setup by travelgenix.io - upon adding this integration, you will be charged immediately a setup fee of £${this.form.setupPrice.toFixed(2)}, 
        the monthly fee of £${this.form.monthlyPrice.toFixed(2)} will be added to your subscription fees once the setup is completed.`
    },
    doesNotRequireSetupVerbiage () {
      const totalPrice = (this.form.setupPrice + this.form.monthlyPrice).toFixed(2)
      if (totalPrice == 0) {
        return "This integration has no fees (no setup charge or monthly fees)."
      }
      return `Upon adding this integration, you will be charged immediately a cost of £${totalPrice} (£${this.form.setupPrice.toFixed(2)} setup fee + first month of £${this.form.monthlyPrice.toFixed(2)}), 
        the monthly fee of £${this.form.monthlyPrice.toFixed(2)} will be added to your subscription fees.`
    },
    enableConfirmButton () {
      return this.selectedChecklistItems.length == this.checklistItems.length
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  created () {
    //Check if user has acccess to the company
    const hasAccess = this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)
    if(!hasAccess) {
      this.$router.push('/error-404')
      return
    }
    this.loadData(this.applicationId)
  },
  methods: {
    loadData (applicationId) {
      this.$vs.loading()
      this.$http.get(`api/supplierIntegrations/apps/${applicationId}/supplier/${this.supplierId}`)
      .then(response => {
        // destructure response data to remove array
        const { integrations, checklistItems, ...responseWithoutIntegrations} = response.data;
        this.form = Object.assign({}, responseWithoutIntegrations)
        this.integrationsData = response.data.integrations
        this.checklistItems = response.data.checklistItems
        this.hasLoaded = true
      })
      .catch(err => console.error(err) )
      .finally(() => this.$vs.loading.close())

      this.$http.get(`api/supplierIntegrations/apps/${applicationId}/supplier/${this.supplierId}/healthcheck`)
      .then(response => {
        this.healthCheckItems = response.data.healthCheckItems
      })
      .catch(err => console.error(err) )
      .finally()

    },
    addIntegration() {
      this.showAddIntegrationPopup=false
      this.$router.push({ name: 'supplier-integrations-addition', params: { applicationId: this.applicationId, supplierId: this.supplierId } })
    },
    handleDeleteIntegration(integrationId) {
      this.$vs.loading()
      this.$http.delete(`api/supplierintegrations/apps/${this.applicationId}/integrations/${integrationId}`)
      .then(response => {
        this.integrationsData = this.integrationsData.filter(integration => integration.threadId != integrationId)
        this.$_notifySuccess('Integration settings successfully deleted');
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    closeAddIntegrationDialog () {
      
    },
    handleCancel () {
      this.$router.push({ name: 'supplier-integrations-directory', params: { applicationId: this.applicationId} })
    }
  }
}
</script>
<style lang="scss" scpoped>
  .supplier-logo-avatar {
    .con-vs-avatar {
      border-radius: 10%;
    }
    .vs-avatar--con-img {
      border-radius: 10%;
    }
  }
  .supplier-name-heading {
    color: #636363
  }
</style>
