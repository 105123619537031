<template>
  <div v-if="healthCheckItems && healthCheckItems.length > 0" class="vx-row">
    <div class="vx-col lg:w-1/4 xs:w-full" v-for="(stat, index) in healthCheckData" :key="index">
      <div class="text-sm">Mean {{stat.bookStage}} Time</div>

      <travelify-supplier-health-check-stats 
        v-if="stat.requestCount7Days > 0"
        :statValue="stat.meanTimeLastHour"
        :color="stat.meanTimeLastHourColor" />
      <!-- <travelify-supplier-health-check-stats v-else statValue="No data" color="#e2e2e2" /> -->

      <travelify-supplier-health-check-stats 
        v-if="stat.requestCount7Days > 0"
        :color="stat.meanTimeLast7DaysColor"
        :statValue="stat.meanTimeLast7Days" />
      <travelify-supplier-health-check-stats v-else statValue="No data" color="#e2e2e2" />

      <div class="text-sm mt-4">{{stat.bookStage}} Error Rates</div>
      <travelify-supplier-health-check-stats
        v-if="stat.requestCount7Days > 0"
        :color="stat.errorRateLastHourColor"
        :statValue="stat.errorRateLastHour" />
      <!-- <travelify-supplier-health-check-stats v-else statValue="No data" color="#e2e2e2" /> -->

      <travelify-supplier-health-check-stats
        v-if="stat.requestCount7Days > 0"
        :color="stat.errorRateLast7DaysColor"
        :statValue="stat.errorRateLast7Days" />
      <travelify-supplier-health-check-stats v-else statValue="No data" color="#e2e2e2" />
    </div>
  </div>
</template>

<script>
import TravioAccessControl from '../../components/travio-pro/TravioAccessControl.vue';
import TravelifySupplierHealthCheckStats from '@/components/travio-pro/TravelifySupplierHealthCheckStats.vue'

export default {
  components: {
    TravioAccessControl,
    TravelifySupplierHealthCheckStats
  },
  props: {
    healthCheckItems: { required: true }
  },
  data () {
    return {}
  },
  computed: {
    healthCheckData () {
      return this.healthCheckItems.map(x => {
        let errorPctLast7Days = x.requestCount7Days != 0 
          ? Math.round(x.errorCount7Days / x.requestCount7Days * 100) 
          : 0

        return {
          bookStage: x.bookStage,
          meanTimeLastHour: `${x.avgTimeHour}s last hour`,
          meanTimeLastHourColor: this.meanTimeColor(x.avgTimeHour),
          meanTimeLast7Days: `${x.avgTime7Days}s last 7 days`,
          meanTimeLast7DaysColor: this.meanTimeColor(x.avgTime7Days),
          errorRateLastHour: `${x.errorPctHour}% last hour`,
          errorRateLastHourColor: this.errorRateColor(x.errorPctHour),
          errorRateLast7Days: `${x.errorCount7Days} errors of ${x.requestCount7Days} searches last 7 days`,
          errorRateLast7DaysColor: this.errorRateColor(errorPctLast7Days),
          requestCount7Days: x.requestCount7Days,
        }
      });
    },
    healthChecFirstkData () {
      return this.healthCheckData[0]
    }
  },
  mounted () {
  },
  methods: {
    meanTimeColor(seconds) {
      if (seconds > 10 && seconds <= 20) return 'orange'
      if (seconds > 20) return 'red'
      return 'green'
    },
    errorRateColor(errorPct) {
      if (errorPct > 10 && errorPct <= 20) return 'orange'
      if (errorPct > 20) return 'red'
      return 'green'
    }
  }
}
</script>

<style>

</style>