<template>
  <div>
    <span v-html="params.data.description"></span>
  </div>
</template>

<script>
export default {
  name: 'CellRendererHtmlColumn',
  computed: {

  },
  methods: {
  
  }
}
</script>
