<template>
  <travio-modal width='650' height="250" zIndex="9999">
    <h3 slot="header">Images: {{ uniqueRef }} (max 3, JPG only, max 400kb)</h3>
    <div slot="body">
      <div class="flex items-start mb-2">
        <vs-button title="Max of 3 images per unique ref" class="ml-4" color="primary" @click="handleAddImageClick" type="border" size="small">Upload Image</vs-button>      
        <input type="file" hidden id="imageInput" ref="imageInput" v-on:change="onAddImage" accept="image/*" />
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material mb-3"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="imageList"
        :gridOptions="gridOptions"
        :rowDragManaged="true"
        :animateRows="true"
        domLayout="autoHeight"
        rowSelection="single"
        :pagination="false"
        :suppressPaginationPanel="true"
        :context="context"
        @first-data-rendered="onFirstDataRendered"
        @grid-ready="onGridReady"
        ></ag-grid-vue>
      
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Close</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererImageActions from '../cell-renderers/CellRendererImageActions.vue'
import CellRendererImageThumbnail from '../cell-renderers/CellRendererImageThumbnail.vue'

export default {
  components: {
    TravioModal,
    AgGridVue,
    CellRendererImageActions,
    CellRendererImageThumbnail
  },
  
  data () {
    return {
      cellRendererComponents: {
        CellRendererImageActions,
        CellRendererImageThumbnail
      },
      // imageList: [],
      imagetInEdit: {},
      currentImage: null
    }
  },
  props: {
    applicationId: { required: true },
    uniqueRef: { type: String, required: true },
    imageList: { type: Array, required: true, default: [] },
  },
  created () {
    // this.imageList = this.imageData.images
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { field: 'orderId', hide: true },
      { field: 'previewUrl', hide: true },
      { field: 'fileInput', hide: true },
      { headerName: 'Image', rowDrag: true, width: 250, sortable: false,  suppressSizeToFit: false, cellRendererFramework: Vue.extend(CellRendererImageThumbnail), valueGetter: params => params.data.id },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true,  sortable: false, cellRendererFramework: Vue.extend(CellRendererImageActions) },
    ]


    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

    this.gridOptions.onRowDragEnd = async (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.imageList, x => x.id == rowNodeGettingDragged.data.id)
      const toIndex = e.overIndex
      array_move(this.imageList, fromIndex, toIndex)
      
      try {
        let response = await this.$http.post(
          `api/accomomodationOverrides/apps/${this.applicationId}/images/${this.uniqueRef}/order`,
          { orderItems: this.imageList })
        // this.$emit('onReorder')
        this.$_notifySuccess('Successfully reordered images');
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    }
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    handleAddImageClick() {
      if (this.imageList.length >= 3) {
        this.$vs.notify({
          title: 'Max of 3 images per unique ref',
          text: 'Please delete an image to add a new one',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
        return
      }
      this.$refs.imageInput.click()
    },
    onAddImage () {
      
      this.currentImage = this.$refs.imageInput.files[0];
      this.$emit('onAddImage', {
        uniqueRef: this.uniqueRef,
        image: this.currentImage
      })
    },
    handleDelete (id) {
      this.$emit('onDeleteImage', {
        uniqueRef: this.uniqueRef,
        id: id
      })
    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>
