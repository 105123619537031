<template>
  <div class="my-3 image-list-container">
     <a :href="params.data.previewUrl" target="_blank">
      <img :src="params.data.previewUrl" class="image-list-img">
     </a>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
    // this.params.context.componentParent.onThumbnailMounted(this.params.data, this.params.rowIndex) 
  },
  methods: {
    editRecord () {
      // this.params.context.componentParent.onEditImage(this.params.data, this.params.rowIndex) 
    }
  }
}
</script>
<style>
  /* .file-list-container {
    height: 40px;
  } */
  .image-list-container .image-list-img {
    border-radius: 3px;
    height: 60px;
  }
</style>