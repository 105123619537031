<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onLaunchEditor" />
    <feather-icon v-if="showImageUpload" title="Image Upload" icon="ImageIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onImageUpload" />
  </div>
</template>

<script>
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
export default {
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  computed: {
    showImageUpload() {
      return this.appHasFeature('accimgupld') 
        && this.params.data.uniqueRef
        && this.params.data.uniqueRef.toLowerCase().startsWith("tti:")
    }
  },
  methods: {
    onLaunchEditor () {
      this.params.context.componentParent.launchEditor(this.params.data) 
    },
    onImageUpload() {
      this.params.context.componentParent.launchImageUpload(this.params.data)
    }
  }
}
</script>
