var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-3 image-list-container" }, [
    _c("a", { attrs: { href: _vm.params.data.previewUrl, target: "_blank" } }, [
      _c("img", {
        staticClass: "image-list-img",
        attrs: { src: _vm.params.data.previewUrl },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }