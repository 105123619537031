var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-modal",
    { attrs: { width: "650", height: "250", zIndex: "9999" } },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(
          "Images: " + _vm._s(_vm.uniqueRef) + " (max 3, JPG only, max 400kb)"
        ),
      ]),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c(
            "div",
            { staticClass: "flex items-start mb-2" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    title: "Max of 3 images per unique ref",
                    color: "primary",
                    type: "border",
                    size: "small",
                  },
                  on: { click: _vm.handleAddImageClick },
                },
                [_vm._v("Upload Image")]
              ),
              _c("input", {
                ref: "imageInput",
                attrs: {
                  type: "file",
                  hidden: "",
                  id: "imageInput",
                  accept: "image/*",
                },
                on: { change: _vm.onAddImage },
              }),
            ],
            1
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material mb-3",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.imageList,
              gridOptions: _vm.gridOptions,
              rowDragManaged: true,
              animateRows: true,
              domLayout: "autoHeight",
              rowSelection: "single",
              pagination: false,
              suppressPaginationPanel: true,
              context: _vm.context,
            },
            on: {
              "first-data-rendered": _vm.onFirstDataRendered,
              "grid-ready": _vm.onGridReady,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "danger", type: "filled" },
              on: { click: _vm.close },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }