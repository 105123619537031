<template>
  <div v-if="integrationsData">
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="integrationsData"
      :gridOptions="gridOptions"
      rowSelection="single"
      :pagination="false"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :context="context"
      :style="{ width:'100%', height:'200px' }"
    >
    </ag-grid-vue>
    <!-- <vs-pagination
      :total="totalPages"
      :max="5"
      v-model="currentPage" /> -->
  </div>
</template>

<script>
import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererCredentialValidity from './cell-renderers/CellRendererCredentialValidity.vue'
import CellRendererIntegrationsActions from './cell-renderers/CellRendererIntegrationsActions.vue'
import CellRendererCheckbox from './cell-renderers/CellRendererCheckbox.vue'
import CellRendererIsSharedCheckbox from './cell-renderers/CellRendererIsSharedCheckbox.vue'
import TravioAccessControl from '../../components/travio-pro/TravioAccessControl.vue';
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
    TravioAccessControl,
    CellRendererCredentialValidity,
    CellRendererIntegrationsActions,
    CellRendererCheckbox,
    CellRendererIsSharedCheckbox
  },
  props: {
    applicationId: { required: true },
    integrations: { required: true }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererCredentialValidity,
        CellRendererIntegrationsActions,
        CellRendererCheckbox,
        CellRendererIsSharedCheckbox
      },
      context: null,
      showDeleteRolePopup: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser;
    },
    integrationsData () {
      return this.integrations
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'threadId', hide: true },
      { field: 'applicationId', hide: true },
      { field: 'supplierId', hide: true },
      { field: 'isCredInvalid', hide: true },
      { field: 'isEditable', hide: true },
      { headerName: 'Actions', sortable: true, suppressSizeToFit: true, width: 150, cellRendererFramework: Vue.extend(CellRendererIntegrationsActions) },
      { headerName: '', sortable: true, suppressSizeToFit: true, width: 60, cellRendererFramework: Vue.extend(CellRendererCredentialValidity) },
      { headerName: 'Description', field: 'description', sortable: true, suppressSizeToFit: false, width: 250 },
      { headerName: 'Enabled', field: 'isActive', sortable: true, suppressSizeToFit: true, width: 120, cellRendererFramework: Vue.extend(CellRendererCheckbox) },
      { headerName: 'Shared', field: 'isShared', sortable: true, suppressSizeToFit: false, width: 140, cellRendererFramework: Vue.extend(CellRendererIsSharedCheckbox) },
      { 
        headerName: 'Created Date',
        field: 'createdDate',
        sortable: true,
        suppressSizeToFit: true,
        width: 220,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      },
       { 
        headerName: 'Updated Date',
        field: 'updatedDate',
        sortable: true,
        suppressSizeToFit: true,
        width: 220,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    //to be called from CellRenderer
    deleteIntegration (threadId) {
      this.$emit('onDeleteIntegration', threadId)
    }
  }
}
</script>

<style>

</style>