<template>
  <div v-if="checklistItemsData">
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="checklistItemsData"
      :gridOptions="gridOptions"
      rowSelection="single"
      :pagination="false"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :style="{ width:'100%', height:'200px' }"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererHtmlColumn from './cell-renderers/CellRendererHtmlColumn.vue'

export default {
  components: {
    AgGridVue,
    CellRendererHtmlColumn
  },
  props: {
    checklistItems: { required: true }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: { CellRendererHtmlColumn },
      context: null,
      showDeleteRolePopup: false
    }
  },
  computed: {
    checklistItemsData () {
      return this.checklistItems
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
     
      { field: 'id', hide: true },
      { headerName: 'Title', field: 'title', sortable: true, suppressSizeToFit: true, width: 250 },
      { headerName: 'Description', field: 'description', sortable: true, suppressSizeToFit: false, width: 200, cellRendererFramework: Vue.extend(CellRendererHtmlColumn) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
   
  }
}
</script>

<style>

</style>